import { IS_CANARY } from "shared-utils"

export const NOVU_CONFIGURATION: {
  novuAppId: string
  novuBackendUrl: string
  novuSocketUrl: string
} = {
  novuAppId: process.env.NEXT_PUBLIC_NOVU_APP_ID ?? "",
  novuBackendUrl: process.env.NEXT_PUBLIC_API_URL ?? "",
  novuSocketUrl: process.env.NEXT_PUBLIC_WS_URL ?? "",
}
export const NEXT_PUBLIC_GOOGLE_API_KEY: string =
  process.env.NEXT_PUBLIC_GOOGLE_API_KEY || ""

export const SENBIRD_APP_ID: string =
  process.env.NEXT_PUBLIC_APP_ID_SENDBIRD ?? ""

export const CDN_URL = process.env.NEXT_PUBLIC_CDN_URL ?? ""

export const ZENDESK_URL = process.env.NEXT_PUBLIC_ZENDESK_URL ?? ""

export const FIREBASE_CONFIG: {
  apiKey: string
  authDomain: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId: string
  vapidKey: string
} = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY ?? "",
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN ?? "",
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID ?? "",
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET ?? "",
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID ?? "",
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID ?? "",
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID ?? "",
  vapidKey: process.env.NEXT_PUBLIC_FIREBASE_VAPID_KEY ?? "",
}

export const AUTH = {
  BASE_URL: process.env.NEXT_PUBLIC_AUTH_BASE_URL_SELLER ?? "",
}

export const PRIVY_OAUTH = {
  clientId: process.env.NEXT_PUBLIC_PRIVY_OAUTH_CLIENT_ID ?? "",
  scope: process.env.NEXT_PUBLIC_PRIVY_OAUTH_SCOPE ?? "",
  url: process.env.NEXT_PUBLIC_PRIVY_OAUTH_URL ?? "",
  dashboardUrl: process.env.NEXT_PUBLIC_PRIVY_DASHBOARD_URL ?? "",
}

export const ANALYTICS = {
  GTM_ID: process.env.NEXT_PUBLIC_GTM_ID ?? "",
}

export const PLATFORM = {
  buyer: IS_CANARY
    ? "https://buyer-canary.inaproc.id"
    : process.env.NEXT_PUBLIC_BUYER_URL || "",
}

export const DOWNLOAD_REQUEST_URL =
  process.env.NEXT_PUBLIC_DOWNLOAD_REQUEST_URL ?? ""

export const ACCOUNT_PPN_URL = process.env.NEXT_PUBLIC_ACCOUNT_PPN_URL ?? ""

export const GOOGLE_SITE_VERIFICATION =
  process.env.GOOGLE_SITE_VERIFICATION ?? ""
