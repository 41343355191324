"use client"

import { useRouter, useSearchParams } from "next/navigation"
import { getCsrfToken, useSession } from "next-auth/react"
import { useEffect } from "react"
import { Spinner } from "shared-ui"

import { signIn } from "@/authentication/authService"

const LoginPage = () => {
  const session = useSession()
  const router = useRouter()
  const callbackUrl = useSearchParams()?.get("callbackUrl") || ""

  const fetchCsrfToken = async () => {
    const result = await getCsrfToken()
    if (!result) {
      throw new Error("Can not sign in without a CSRF token")
    }
  }

  const redirectToError = () => {
    router.push(
      `/auth/error?error_description=${session?.data?.error}&tracking=-`
    )
  }

  useEffect(() => {
    if (session?.data?.error === "ERROR_GET_USER") {
      redirectToError()
    } else if (session?.status !== "loading") {
      fetchCsrfToken()
      signIn("auth0", { callbackUrl })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.status])

  return (
    <div className="grid h-full w-full items-center justify-center">
      <div>
        <Spinner size="large" color="primary" className="!m-auto block" />
        <div className="mt-5 text-primary600">Please wait...</div>
      </div>
    </div>
  )
}

export default LoginPage
