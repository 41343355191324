import {
  signIn as signInService,
  signOut as signOutService,
} from "next-auth/react"
import {
  type TProvider,
  authSignIn,
  authSignOut,
} from "shared-utils/authentication"

import { AUTH as PUBLIC } from "@/config/api"
import { AUTH } from "@/config/client"

export const signOut = (redirect?: boolean) => {
  return signOutService({
    redirect,
  }).finally(() =>
    authSignOut({ domain: PUBLIC.DOMAIN, baseUrl: AUTH.BASE_URL })
  )
}

type TSignIn = {
  callbackUrl?: string
}

export const signIn = async (provider: TProvider, props?: TSignIn) => {
  const signInOptions = await authSignIn(provider, {
    ...props,
    baseUrl: AUTH.BASE_URL,
    domain: PUBLIC.DOMAIN,
  })
  if (signInOptions) {
    signInService("auth0", { ...signInOptions })
  }
}
